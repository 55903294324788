<template>
    <div class="slideshow">
        <el-carousel arrow="always">
          <el-carousel-item v-for="item in slideshowList" :key="item.id">
            <el-image :fit="fits[0]" :src="item.imgPath" @click="handleJump(item.jumpPath)"></el-image>
          </el-carousel-item>
        </el-carousel>
        <img src="~static/tzn.png" alt="">
        <!-- <el-image
          style="width: 100px; height: 100px;position: absolute;"
          src="～/tzn.png"
          fit="contain"></el-image> -->
    </div>
  </template>
  
  <script>
  export default {
    name: "slideshow",
    data() {
      return {
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      }
    },
    props: {
        slideshowList: [],
        required: true
    },
    methods:{
      handleJump(jumpPath){
        console.log(jumpPath,'-----')
        this.$emit('gotodetail',jumpPath)
      }
    }
  };
  </script>
  <style scoped>
    >>>.el-carousel__container{
      height: 600px;
    }
    >>>.el-carousel__indicators--horizontal{
      bottom: 50px;
    }
    .slideshow{
      position: relative;
    }
    .slideshow>img{
      width: 400px;
      top: 20%;
      z-index: 9;
      left: 37%;
      position: absolute;
    }
    >>>.el-carousel__arrow--left{
      background-image: url('../../static/home/carousel-left.png');
    }
    >>>.el-carousel__arrow--right{
      background-image: url('../../static/home/carousel-right.png');
    }
    >>>.el-carousel__arrow {
      width: 106px;
      height: 106px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    >>>.el-image{
      width: 100%;
      height: 100%;
    }
    >>>.el-image :hover{
      cursor: pointer;
    }
  </style>
  